import React from 'react';

const IconNpm: React.FC = () => {
    return (
        <svg className="svg-npm" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Logo Npm</title>
            <path d='M227.6 213.1H256v57.1h-28.4z'/>
            <path
                d='M0 156v171.4h142.2V356H256v-28.6h256V156zm142.2 142.9h-28.4v-85.7H85.3v85.7H28.4V184.6h113.8zm142.2 0h-56.9v28.6h-56.9V184.6h113.8zm199.2 0h-28.4v-85.7h-28.4v85.7h-28.4v-85.7H370v85.7h-56.9V184.6h170.7v114.3z'/>
        </svg>
    );
};

export default IconNpm;
